import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DayView from '../views/DayView.vue'
import ReportView from '../views/ReportView.vue'
import LoginView from '../views/LoginView.vue'
import SettingsView from '../views/SettingsView.vue'
import DashboardView from '../views/DashboardView.vue'

const routes: Array<RouteRecordRaw> = [
  /*{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },*/
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/day/:date?',
    name: 'day',
    component: DayView,
    meta: { requiresAuth: true }
  },
  {
    path: '/report',
    name: 'report',
    component: ReportView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: { requiresAuth: false }
  },
  {
    path: '/about',
    name: 'about',
    meta: { requiresAuth: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=> {
  const isAuthenticated = localStorage.getItem('user') != null;
    const isProtected = to.matched.some(route=> route.meta.requiresAuth);  

  if ((!isAuthenticated && isProtected)) {
    next({ name : 'login' });  
  } else{
    next();
  }

});

export default router
