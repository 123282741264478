

import { defineComponent, } from 'vue'
import {Login} from '../model/Login'
import authService from '@/services/auth.service'

export default defineComponent ({
  name: 'LoginView',

  data () {
    return {
      snackbar: false,
      user: {} as Login,
      text:"",       
      form: false,     
      loading: false,
      message: "",
   
    }
  },
  computed: {
    loggedIn() {
      //return this.$store.state.auth.status.loggedIn;
      return localStorage.getItem("user")!=null;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {       
        required (v:any) {
        return !!v || 'Ce champ est requis'
        },
        handleLogin() {
          if (!this.form) return        
          this.loading = true; 
          authService.login(this.user).then(()=> {  
          //this.$store.dispatch("auth/login", this.user).then(() => {
            this.$router.push("/day");
          }).catch ((error: any) => {
            this.loading = false;
            if (error.response && error.response.status==401) {
                this.message = "Mauvais identifiant ou mot de passe";          
            } else if (error.response && error.response.status==403) {
                  this.message = "Opération non authorisée";          
            } else {
                this.message = (error.response && error.response.data && error.response.data.message) 
                            || error.message || error.toString();
            }
        }
      );
    },
    }
})
