import { Day } from '@/model/Day';
import axios from 'axios';
import moment from "moment";
import authHeader from './auth-header';

//import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_API_BASE_URL;
class DayService {
  getAllDays() {
    return axios.get(API_URL + 'days');
  }

  updateDay(day: Day) : Promise<Day> {
    return axios.post<Day>(API_URL + 'day',day,{ headers: authHeader() }).then(res=>res.data);
    
  }

  deleteDay(date:Date) : Promise<Day> {
    const strDate = moment(date).format('yyyy-MM-DD');
    return axios.delete(API_URL + 'day/'+strDate, { headers: authHeader() }).then(res=>res.data);    
  }

  getDay(date:Date) : Promise<Day>{
    const strDate = moment(date).format('yyyy-MM-DD');
    return axios.get<Day>(API_URL + 'day/'+strDate,  { headers: authHeader() }).then(res => res.data);
  }
}


export default new DayService();