
import { DayDuration } from '@/model/DayDuration';
import dayDurationService from '@/services/dayduration.service';
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'LastWeeks',  
  props: {
        week: {
            type: Number,
            default: 0,
        },
        year: {
            type: Number,
            default: 0,
        },
    },
  data () {
    return {
      days: [] as DayDuration[],
    }
  },
  mounted() {
    if (this.week && this.year) {
      dayDurationService.getWeek(this.year,this.week).then(res => {
        this.days=res;
      } );
    } else {
      dayDurationService.getCurrentWeek().then(res => {
        this.days=res;
      } );
    }
  },
  methods : {
    navigate(route:string) {
      this.$router.push(route);
    },
  }
})
