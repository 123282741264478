

import DayService from '../services/day.service';
import moment from "moment";
import '../model/Day'

import { defineComponent, } from 'vue'
import {Day} from '../model/Day'

export default defineComponent ({
  name: 'DayView',

  data () {
    return {
      day: {} as Day,
      snackbar: false,
      text:"",
      valid: false,
      rules: [
      (value: any) => {
          if (value) return true

          return 'You must enter a value.'
        },
      ]
      
    }
  },
  created() {
    let dateParam=new Date();
    if (this.$route.params.date) {
        dateParam=new Date(this.$route.params.date.toString());
    }
    DayService.getDay(dateParam).then(res => {
      this.day=res;
    } );
  },
  methods: {
        formatDate(date : Date) {            
                return moment(date).format('DD/MM/yyyy');            
        },
        dateChanged() {
          if (this.day.date) {
            DayService.getDay(this.day.date).then(res => {
              this.day=res;
           });
          }
        },
        validate() {        
          DayService.updateDay(this.day).then(res=> {
            this.day=res;
            this.text="Jour enregistré avec succès."
          }).catch(err=> {
            this.text="Echec de l'enregistrement."
          }).finally(()=> {
            this.snackbar=true;
          });
        },
        deleteDay() {
          let res=confirm('Voulez vous supprimer les données de ce jour ?');
          if (res) {
            DayService.deleteDay(this.day.date).then(()=> {
              this.day.newDay=true;
              this.text="Jour supprimé avec succès"
            }).catch(()=> {
              this.text="Echec de la suppression"
            }).finally(()=> {
              this.snackbar=true;
            });
          }
        }
    }
})
