

import WeekService from '../services/week.service';
import moment from "moment";

import { defineComponent, } from 'vue'
import {Week} from '../model/Week'
import CurrentWeek from '../components/CurrentWeek.vue'

export default defineComponent ({
  name: 'ReportView',
  components: {
    CurrentWeek
  },
  data () {
    return {
      year: null as unknown as number,
      weeks: [] as Week[],
      years: [] as number[],
      viewyear: 0,
      viewweek:0,
      dialog : false       
    }
  },
  mounted() {    
    this.year = moment().year();
    for (var i = this.year; i >= 2019; i--) {
      this.years.push(i);
    }
    WeekService.getWeeks(this.year).then(res => {
      this.weeks=res;
      console.log("Weeks",this.weeks);
    } );
  },
  methods: {
        formatDate(date : Date) {            
                return moment(date).format('DD/MM/yyyy');            
        },
        yearChanged() {
          if (this.year) {
            WeekService.getWeeks(this.year).then(res => {
              this.weeks=res;
           });
          }
        },
        view(year:number,week:number) {
          this.dialog=true;
          this.viewyear=year;
          this.viewweek=week;
        }
    }
})
