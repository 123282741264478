
import { DayDuration } from '@/model/DayDuration';
import axios from 'axios';
import authHeader from './auth-header';


//import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_API_BASE_URL;
class DayDurationService {

  getCurrentWeek() : Promise<DayDuration[]> {  
    return axios.get<DayDuration[]>(API_URL + 'currentweek',{ headers: authHeader() }).then(res=>res.data);
  }

  getWeek(year:number, week:number) : Promise<DayDuration[]> {  
    return axios.get<DayDuration[]>(API_URL + 'dayduration/'+year+'/'+week,{ headers: authHeader() }).then(res=>res.data);
  }

}


export default new DayDurationService();