export class Day {

    newDay!:boolean;

    congesApres!:boolean;
    congesMatin!:boolean;
    date!: Date;
    debutApres!: string;
    debutMatin!: string;
    ferie!: boolean;
    finApres!: string;    
    finMatin!: string;
    idUtilisateur!: number;
  
  }