
import { Week } from '@/model/Week';
import weekService from '@/services/week.service';
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'LastWeeks',

  data () {
    return {
      weeks: [] as Week[],
    }
  },
  mounted() {
    weekService.getLastWeeks().then(res => {
      this.weeks=res;
    } );
  }
})
