
import { Login } from '@/model/Login';
import axios from 'axios';
import authHeader from './auth-header';

//import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_API_BASE_URL;

class AuthService  {
  login(user : Login) {
    return axios
      .post(API_URL + 'auth/signin', {
        username: user.username,
        password: user.password
      },{ headers: authHeader()})
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }
  register(user:any) {
    return axios.post(API_URL + 'auth/signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }


}


export default new AuthService();