
import { defineComponent } from 'vue';
import {Week} from '../model/Week'
import LastWeeks from '../components/LastWeeks.vue'
import CurrentWeek from '../components/CurrentWeek.vue'
import moment from "moment";

export default defineComponent({
  name: 'DashboardView',
  components: {
    LastWeeks,CurrentWeek
  },
  data () {
    return {
      weeks: [] as Week[],
      snackbar: false,
      text:"",
      weekNumber : 0,
      today : ""
        
      
    }
  },
  created () {
    let mom = moment();
    this.weekNumber=mom.week();
    this.today=mom.format("DD-MM-YYYY");
  },
  methods : {
    navigate(route:string) {
      this.$router.push(route);
    },
  }
});
