

import SettingsService from '../services/settings.service';
import moment from "moment";
import '../model/Day'

import { defineComponent, } from 'vue'
import {Settings} from '../model/Settings'

export default defineComponent ({
  name: 'SettingsView',

  data () {
    return {
      settings: {} as Settings,
      snackbar: false,
      text:"",
      valid: false,
      loading:false,
      form : false,
      tab: "MONDAY",
      headers: [
          {title: 'Jour',
            align: 'start',
            sortable: false,
            key: 'dayOfWeek',
          },
          { title: 'deb M', align: 'end', key: 'debutMatin' },
          { title: 'fin M', align: 'end', key: 'finMatin' },
          { title: 'deb A', align: 'end', key: 'debutApres' },
          { title: 'fin A', align: 'end', key: 'finApres' }
        ]
    
      
    }
  },
  mounted() {
    SettingsService.getSettings().then(res => {
      this.settings=res;
    } );
  },
  methods: {
        formatDate(date : Date) {            
                return moment(date).format('DD/MM/yyyy');            
        },      
        validate() {        
          console.log(this.settings);
          SettingsService.updateSettings(this.settings).then(res=> {
            this.settings=res;
            this.text="Préférences enregistrées avec succès."
          }).catch(err=> {
            this.text="Echec de l'enregistrement."
          }).finally(()=> {
            this.snackbar=true;
          });
        },
        required (v: any) {
        return !!v || 'Ce champ est requis'
        },
        getDayLabel(dow:string) {
          let res="";
          switch (dow) {
            case 'MONDAY' : {
              res= "Lundi";
              break;
            }
            case 'TUESDAY' : {
              res= "Mardi";
              break;
            }
            case 'WEDNESDAY' : {
              res= "Mercredi";
              break;
            }
            case 'THURSDAY' : {
              res= "Jeudi";
              break;
            }
            case 'FRIDAY' : {
              res= "Vendredi";
              break;
            }
            case 'SATURDAY' : {
              res= "Samedi";
              break;
            }
            case 'SUNDAY' : {
              res= "DIMANCHE";
              break;
            }
            default : {
              res=dow;
              break;
            }
          }
          return res;
        }
    }
})
