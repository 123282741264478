
import { Week } from '@/model/Week';
import axios from 'axios';
import authHeader from './auth-header';


//import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_API_BASE_URL;
class WeekService {
  getWeeks(year: number) : Promise<Week[]> {
    let arg = "";
    if (year) arg="/"+year;
    return axios.get<Week[]>(API_URL + 'weeks'+arg,{ headers: authHeader() }).then(res=>res.data);
  }

  getLastWeeks() : Promise<Week[]> {  
    return axios.get<Week[]>(API_URL + 'lastweeks',{ headers: authHeader() }).then(res=>res.data);
  }

}


export default new WeekService();