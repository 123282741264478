import { Settings } from '@/model/Settings';
import axios from 'axios';
import authHeader from './auth-header';

//import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_API_BASE_URL;
class SetingsService {
  
  updateSettings(settings: Settings) : Promise<Settings> {
    return axios.post<Settings>(API_URL + 'settings',settings,{ headers: authHeader() }).then(res=>res.data);
    
  }

  getSettings() : Promise<Settings>{   
    return axios.get<Settings>(API_URL + 'settings',  { headers: authHeader() }).then(res => res.data);
  }
 
}


export default new SetingsService();