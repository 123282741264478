
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',

  data () {
    return {
      //
    }
  },
   computed: {
    currentUser() {
      let res="";
      const user  = localStorage.getItem('user');
      if (user) {
        res = JSON.parse(user).username;
      }
      return res;
    }
  },
  methods: {
    logOut() {
      localStorage.removeItem('user');
      this.$router.push('/login');
    },
    settings() {      
      this.$router.push('/settings');
    },
    home() {      
      this.$router.push('/');
    },
  }
})
